export const heroObj1 = {
  id            : 'hero',
  destination   : 'about',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Introducing Tap To Connect',
  headline      : 'Goodbye paper cards!',
  description   : 'Create and share your digital business card.',
  buttonLabel   : 'Get Started',
  imgStart      : true,
  img           : require('../../assets/images/TapToNetworkLogo.svg').default,
  alt           : 'GlobePins',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj1 = {
  id            : 'about',
  destination   : 'discover',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Smart Business Cards',
  headline      : 'Digitize Your Networking Efforts',
  description   : 'Simplify the way you network with our smart business cards. Host your professional profile, store contacts in the cloud, and seamlessly transfer files and media.',
  buttonLabel   : 'Get Started',
  imgStart      : false,
  img           : require('../../assets/images/cloud_sync.svg').default,
  alt           : 'GlobePins',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj2 = {
  id            : 'discover',
  destination   : 'discover2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Lead Generation Made Easy',
  headline      : 'Effortlessly Collect Leads with Tap-to-Contact',
  description   : 'Our lead generation forms will help you grow your business by easily collecting potential clients’ information with a simple tap-to-contact feature.',
  buttonLabel   : 'Learn More',
  imgStart      : true,
  img           : require('../../assets/images/lead_generation.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj3 = {
  id            : 'discover2',
  destination   : 'services',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Seamless Team Collaboration',
  headline      : 'Organize Your Teams and Clients Lists',
  description   : 'Our platform enables you to organize your teams and clients in one place. Access client information, schedule meetings, and transfer files with ease.',
  buttonLabel   : 'Get Organized',
  imgStart      : false,
  img           : require('../../assets/images/team.svg').default,
  alt           : 'Accounts',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj4 = {
  id            : 'signup',
  destination   : 'events',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Video and Photo Integration',
  headline      : 'Elevate Your Professional Presence',
  description   : 'Enhance your profile and business card with our video and photo integration. Showcase your portfolio, products, or services in an engaging way.',
  buttonLabel   : 'Showcase Now',
  imgStart      : true,
  img           : require('../../assets/images/transfer_files.svg').default,
  alt           : 'Papers',
  dark          : false,
  primary       : false,
  darkText      : true
};


export const homeObj5 = {
  id            : 'events',
  destination   : '/signup',
  isScroll      : false,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Business Event Management',
  headline      : 'Simplify Your Business Event Planning',
  description   : 'Our platform helps you manage business events with ease. Plan meetings, invite guests, and send reminders to ensure a successful event.',
  buttonLabel   : 'Plan Your Event',
  imgStart      : false,
  img           : require('../../assets/images/online_media.svg').default,
  alt           : 'Store',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const pageNotReadyObj1 = {
  id            : '404',
  destination   : '/contact',
  isScroll      : false,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Need Our Products?',
  headline      : 'Order products directly from our store!',
  description   : 'From our aesthetic organizer totes, to custom signs and containers, order our products directly from our ecommerce store!',
  buttonLabel   : 'Visit Our Online Store',
  imgStart      : false,
  img           : require('../../assets/images/page_not_found.svg').default,
  alt           : 'Store',
  dark          : true,
  primary       : true,
  darkText      : false
};