import Container      from './../../components/Container';
// import Navbar         from './../../components/Navbar';
import HeroSection    from './../../components/HeroSection';
import InfoSection    from './../../components/InfoSection';
import Services               from './../../components/Services';
import {
  homeObj1,
  homeObj2,
  homeObj3,
  homeObj4,
  homeObj5 } from './../../components/InfoSection/Data';

const Homepage = () => {
  return (
    <Container>
      {/* <Navbar /> */}
      <HeroSection/>
      <InfoSection {...homeObj1} />
      <InfoSection {...homeObj2} />
      <InfoSection {...homeObj3} />
      <Services />
      <InfoSection {...homeObj4} />
      <InfoSection {...homeObj5} />
    </Container>
  );
};

export default Homepage;
