export const sampleUser = {
    name: 'Miguel Brown',
    description: 'Luckey Logic LLC',
    jobTitle: 'Founder/CEO',
    city: 'Luckey',
    state: 'OH',
    image: 'https://firebasestorage.googleapis.com/v0/b/taptonetwork.appspot.com/o/Users%2Ftemp%2Fmiguelbrown.jpeg?alt=media&token=60ded2fd-4146-40f9-8c61-a23a6be0447c',
    links: [
      {
        name: 'LinkedIn',
        url: 'https://linkedin.com/',
      },
      {
        name: 'Facebook',
        url: 'https://facebook.com/LuckeyLogic',
      },
      {
        name: 'Website',
        url: 'https://luckeylogic.com',
      },
    ],
  };