import React from 'react';
import Icon1 from '../../assets/images/ecommerce.svg';
import Icon2 from '../../assets/images/profile.svg';
import Icon3 from '../../assets/images/marketing_tools.svg';
import Icon4 from '../../assets/images/real_time_collaboration.svg';
import Icon5 from '../../assets/images/feedback.svg';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './services.elements.js';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Smart Business Solutions</ServicesH2>
          <ServicesP>
          From NFC-enabled business cards to custom NFC stickers, we provide a range of smart business solutions to help you stand out from the crowd.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Profile Hosting & Management</ServicesH2>
          <ServicesP>
          Say goodbye to outdated paper resumes and hello to your new digital profile. We'll host and manage your professional profile, making it easy for potential clients and partners to find and connect with you.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Digital Marketing Tools</ServicesH2>
          <ServicesP>
          Our range of digital marketing tools will help you reach and engage with your target audience more effectively. From lead generation forms to personalized video messages, we have the tools you need to succeed.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Efficient Workflow Solutions</ServicesH2>
          <ServicesP>
          We'll help you streamline your workflow and get more done in less time. With features like cloud-based contact storage, file transfer, and team organization tools, you'll be able to focus on what really matters - growing your business.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>Customized Business Services</ServicesH2>
          <ServicesP>
          Need something specific for your business? We offer customized services to meet your unique needs. From product design to branding, we'll work with you to create the perfect solution.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
