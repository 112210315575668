// index.js
import React from 'react';
import { Card, Image, Name, Description, JobTitle, CityState, SaveButton, LinkList } from './index.elements';
import vCard from './vCard';

const ContactProfile = ({ user }) => {

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([vCard], {type: "text/vcard;charset=utf-8"});
        element.href = URL.createObjectURL(file);
        element.download = 'contacts.vcf';
        document.body.appendChild(element);
        element.click();
      };

  return (
    <Card>
      <Image src={user.image} />
      <Name>{user.name}</Name>
      <Description>{user.description}</Description>
      <JobTitle>{user.jobTitle}</JobTitle>
      <CityState>{user.city}, {user.state}</CityState>
      <LinkList>
        {user.links.map(link => (
          <a key={link.name} href={link.url}>
            {link.name}
          </a>
        ))}
      </LinkList>
      <SaveButton onClick={handleDownload}>Open Contact Card</SaveButton>
    </Card>
  );
};

export default ContactProfile;
