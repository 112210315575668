import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Container from '../Container';
import { HeaderContainer, Logo, NavLink } from './index.elements';

let logo = require('../../assets/images/TapToNetworkLogoOnlyTransparent.svg').default

const Header = () => {
  return (
    <HeaderContainer>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/" style={{ display: "flex", alignItems: "center" }}>
            <Logo src={logo} alt="TapToNetwork Logo" style={{ marginRight: "10px" }} />
            <span style={{ fontWeight: "600" }}>TapToNetwork</span>
          </Navbar.Brand>
          <Nav className="ml-auto">
            <NavLink as={Link} to="/signup">
              Sign Up
            </NavLink>
            <NavLink as={Link} to="/login">
              Login
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
    </HeaderContainer>
  );
};

export default Header;
